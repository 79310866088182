import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import ProtectedAdminRoute from 'components/Routes/ProtectedAdminRoute';
import ProtectedHagrRoute from 'components/Routes/ProtectedHagrRoute';
import ProtectedClientRoute from 'components/Routes/ProtectedClientRoute';
import ProtectedSellCropsUser from 'components/Routes/ProtectedSellCropsUser';
// css
import 'assets/admin/scss/paper-dashboard.scss?v=1.3.0';
import 'assets/admin/css/react search autocomplete.css';
import 'assets/client/assets/css/meanmenu.min.css';
import 'assets/client/assets/css/barfiller.css';
import 'assets/client/assets/css/style.css';
import 'assets/client/assets/css/responsive.css';
import 'assets/client/assets/css/rtl.css';
import 'assets/client/assets/fonts/font.css';

//layouts (admin, client)
// import AdminLayout from 'layouts/Admin';
import PublicLayout from 'layouts/Public';
import GeneralFallBack from 'components/general/GeneralFallBack/GeneralFallBack.js';
import UserStore from 'contexts/user';
const AdminLayout = React.lazy(() => import('layouts/Admin'));
const HagrLayout = React.lazy(() => import('layouts/Hagr'));
const ClientLayout = React.lazy(() => import('layouts/Client'));
const Certificate = React.lazy(() => import('layouts/certificate/Index'));

const SellCrops = React.lazy(() => import('layouts/SellCrops/SellCrops'));
const LinkTree = React.lazy(() => import('layouts/LinkTree/LinkTree'));
const SellCropsUser = React.lazy(() =>
  import('layouts/SellCropsUser/SellCrops')
);
const loginSellCropsUser = React.lazy(() =>
  import('layouts/loginSellCropsUser/loginSellCropsUser')
);
const BuyCrops = React.lazy(() => import('layouts/BuyCrops/BuyCrops'));
const BuyCropsEnglish = React.lazy(() =>
  import('layouts/BuyCropsEnglish/BuyCrops')
);
// import ClientLayout from 'layouts/Client';
const traceCert = React.lazy(() => import('layouts/traceCertificate/Index'));

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<GeneralFallBack />}>
      <UserStore>
        <Switch>
          <Route path="/certificate/client/:code" component={Certificate} />

          <ProtectedAdminRoute path="/admin" component={AdminLayout} />
          <ProtectedHagrRoute path="/hagr" component={HagrLayout} />

          <ProtectedClientRoute path="/client" component={ClientLayout} />

          <Route
            path="/loginSellCropsUser"
            exact
            component={loginSellCropsUser}
          />
          <ProtectedSellCropsUser
            path="/MahaseelMasr-sellcrops-user"
            exact
            component={SellCropsUser}
          />
          <Route
            path="/loginSellCropsUser"
            exact
            component={loginSellCropsUser}
          />
          <Route path="/MahaseelMasr-sellcrops" exact component={SellCrops} />
          <Route path="/links" exact component={LinkTree} />
          <Route path="/MahaseelMasr-buycrops" exact component={BuyCrops} />
          <Route
            path="/MahaseelMasr-buycrops-worldwide"
            exact
            component={BuyCropsEnglish}
          />

          <Route path="/traceCert/:code" exact component={traceCert} />
          {/* <Route
          path="/sellcrops"
          exact
          render={() =>
            (window.location = 'https://www.mahaseel.org/quotation')
          }
        /> */}

          <Route path="/" render={(props) => <PublicLayout {...props} />} />
        </Switch>
      </UserStore>
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);
